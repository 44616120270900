<template>
  <div class="mb-3">
    <b-row
      class="mb-1 mx-0"
      align-h="end"
    >
      <small class="text-danger">{{ permissionErrorText }}</small>
    </b-row>
    <!-- Row Loop -->
    <b-row
      v-for="member in assignedMembers"
      :id="member.id"
      :key="member.id"
      ref="row"
      class="mb-1"
      align-v="center"
    >
      <!-- Name -->
      <b-col
        cols="2"
      >
        <b-form-input
          v-model="member.name"
          class="user-info text-primary border-secondary"
          size="lg"
          plaintext
          @click="moveTo(member)"
        />
      </b-col>

      <!-- Email -->
      <b-col
        cols="6"
      >
        <b-form-input
          v-model="member.username"
          class="user-info text-primary border-secondary"
          size="lg"
          plaintext
          @click="moveTo(member)"
        />
      </b-col>

      <!-- Permission -->
      <b-col
        cols="4"
      >
        <b-dropdown
          v-ripple="'rgba(113, 102, 240, 0.15)'"
          :text="resolvePermission(member.permission)"
          variant="primary"
          size="lg"
          disabled
          block
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BDropdown, BFormInput,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import router from '@/router'
import { adminRouteName } from '@/router/routes/admin'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    const resolvePermission = value => t(value)
    const assignedMembers = inject('assignedMembers')
    const permissionOptions = inject('permissionOptions')
    const permissionErrorText = inject('permissionErrorText')
    const moveTo = member => {
      router.push({ name: adminRouteName.memberView, params: { memberId: member.id } })
    }

    return {
      assignedMembers,
      permissionOptions,
      permissionErrorText,
      resolvePermission,
      moveTo,
    }
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  text-decoration: underline;
}
</style>
