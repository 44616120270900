<template>
  <form-slot-layout title="통합 스크립트 Tracking Key">
    <div class="d-flex text-nowrap align-items-center">
      <b-form-input
        id="tracker-code"
        v-model="trackerCode"
        readonly
        class="mr-2"
        size="lg"
      />
      <b-button
        variant="primary"
        @click="doCopy"
      >
        복사
      </b-button>
    </div>
  </form-slot-layout>
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import useScriptCode from '@/views/utils/useScriptCode'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BFormInput,
    BButton,
  },
  setup() {
    const { trackerCode } = inject('currentAccount')
    const { copyToClipboard } = useScriptCode()
    const doCopy = () => copyToClipboard(trackerCode.value)
    return {
      trackerCode,
      doCopy,
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: large;
  span {
    display: inline-flex;
    align-items: center;
  }
}
</style>
