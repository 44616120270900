<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <app-form-layout v-else>
    <template #middle>
      <app-account-form title="기본정보">
        <!-- 계정 이름 -->
        <name-form
          :type="type"
          inject-key="currentAccount"
          :read-only="isReadOnly"
        />
        <!-- url -->
        <url-repeater :read-only="isReadOnly" />
        <!-- 사업자 등록 번호 -->
        <tax-number-input :read-only="isReadOnly" />
        <!-- tracker code -->
        <tracker-code v-if="type==='광고계정'"/>
      </app-account-form>

      <app-account-form title="멤버">
        <!-- 멤버 리스트 -->
        <account-members />
        <!-- submit button -->
        <template #submit-button>
          <b-button
            v-b-modal="isActivated ? 'modal-alert-disable' : 'modal-alert-activate'"
            class="submit-btn"
            variant="primary"
            block
          >
            {{ isActivated ? '비활성화하기' : '활성화하기' }}
          </b-button>

          <!-- modal alert toggle -->
          <b-modal
            id="modal-alert-disable"
            title=""
            ok-title="비활성화 하기"
            cancel-title="취소"
            ok-variant="primary"
            cancel-variant="outline-secondary"
            centered
            @ok="toggleStatus"
          >
            <b-card-text>
              {{ `'${name}' ${type}을 비활성화 하시겠습니까?` }}
            </b-card-text>
          </b-modal>
          <b-modal
            id="modal-alert-activate"
            title=""
            ok-title="활성화 하기"
            cancel-title="취소"
            ok-variant="primary"
            cancel-variant="outline-secondary"
            centered
            @ok="toggleStatus"
          >
            <b-card-text>
              {{ `'${name}' ${type}을 활성화 하시겠습니까?` }}
            </b-card-text>
          </b-modal>
        </template>
      </app-account-form>
    </template>
  </app-form-layout>
</template>

<script>
import {
  BButton, BModal, BCardText, VBModal,
} from 'bootstrap-vue'
import { computed, onMounted } from '@vue/composition-api'
import useAccount from '@/views/utils/account/useAccount'
import useAccountTask from '@/views/utils/task/useAccountTask'
import router from '@/router'
import AppAccountForm from '@/views/layouts/AppAccountForm.vue'
import NameForm from '@/views/components/NameForm.vue'
import TaxNumberInput from '@/views/components/account/TaxNumberInput.vue'
import UrlRepeater from '@/views/components/account/UrlRepeater.vue'
import AccountMembers from '@/views/components/account/MembersAdmin.vue'
import { isManager } from '@/libs/acl/protection'
import { useRouter } from '@core/utils/utils'
import TrackerCode from '@/views/components/account/TrackerCode.vue'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import { adminRouteName } from '@/router/routes/admin'

export default {
  components: {
    AppFormLayout,
    AppAccountForm,
    TrackerCode,
    NameForm,
    TaxNumberInput,
    UrlRepeater,
    BButton,
    AccountMembers,
    BModal,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    type: {
      get() {
        if (this.$route.name === adminRouteName.adAccountView) {
          return '광고계정'
        }
        return '채널계정'
      },
    },
  },
  setup() {
    const {
      setCurrentAccount,
      makeAccountData,
      currentAccount,
    } = useAccount()

    const {
      loading,
      error,
      fetchAdAccount,
      updateAdAccount,
      fetchChannelAccount,
      updateChannelAccount,
    } = useAccountTask()

    const { route } = useRouter()

    const { status, name } = currentAccount
    const isReadOnly = computed(() => isManager())
    const isActivated = computed(() => status.value === 'ACTIVATED')

    // 컴포넌트 마운트 시 데이터 fetch
    onMounted(async () => {
      if (route.value.name === adminRouteName.adAccountView) {
        const { adId } = route.value.params
        const account = await fetchAdAccount(adId)
        setCurrentAccount(account)
      } else {
        const { channelId } = route.value.params
        const account = await fetchChannelAccount(channelId)
        setCurrentAccount(account)
      }
    })

    // 업데이트 후 페이지 새로고침
    const toggleStatus = async () => {
      if (isActivated.value) {
        status.value = 'DISABLED'
      } else {
        status.value = 'ACTIVATED'
      }
      const account = makeAccountData()
      if (route.value.name === adminRouteName.adAccountView) {
        await updateAdAccount(account)
      } else {
        await updateChannelAccount(account)
      }
      router.go()
    }

    return {
      loading,
      error,
      toggleStatus,
      isReadOnly,
      isActivated,
      name,
    }
  },
}
</script>
<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
